// czerwony $primary-color:#bd0000;
$primary-color:#5393f2 !default;

$secondary-color:#8c8c8c !default;
$secondary-darker: #3b3b3b !default;
$secondary-darker-2: #272727 !default;

$light-gray: #f5f5f5 !default;
$grey-lighter: #ececec !default;

$main-background-color: white !default;

$mobile-breakpoint: 800px !default;

$min_width: 320px !default;
$max_width: 1280px !default;

$text-color: #333 !default;
$footer-accents: #555 !default; 
$footer-icons-color: #858585 !default;

$linked-text-color: $primary-color !default;

$input-height-default: 40px !default;
$input-border-radius-default: 0 !default;
$input-default-border: 1px solid #ccc !default;
$input-placeholder-text-color: $text-color !default;
$input-default-background: none !default;