[tabindex]:not([tabindex="-1"]), a, button, input, select, textarea {
    outline: 2px solid transparent !important;
    outline-offset: 5px !important;
    transition: outline-offset .2s linear !important;
}
[tabindex]:not([tabindex="-1"]):focus, a:focus, button:focus, input:focus, select:focus:focus, textarea:focus {
    outline: 2px solid currentColor !important;
    outline-offset: 2px !important;
    z-index: 1000;
}
.become-vol:focus {
    color: white !important;
}
body.contrast *:not(.navbar-toggle):not(.tp-bgimg):not(.pp_pic_holder):not(.pp_content_container):not(.pp_left):not(.pp_right):not(.pp_content):not(.pp_fade):not(.pp_previous):not(.pp_next):not(.pp_hoverContainer)  {
    background: black !important;
    color: white !important;
    background-image: none !important;
}
body.contrast .news-box {
    border: 1px solid white !important;
}
body.contrast .depart-info .deprt-icon-box {
    border: 1px solid white !important;
}
body.contrast img {
    background: white !important;
}
body.contrast .h3-navbar .navbar-form {
    border: 2px solid white;
    border-radius: 0;
}
body.contrast .h3-navbar .navbar-form .form-control, body.contrast .h3-navbar .navbar-form button{
    height: 26rem;
}
body.contrast .h3-navbar {
    border-bottom: 1rem solid white;
}
body.contrast .navbar-nav>li>.dropdown-menu {
    border: 1rem solid white;
}
body.contrast ::placeholder {
    color: white !important;
}
body.contrast .inpl-weather-main body {
    background: black !important;
    background-color: black !important;
}
body.contrast img {
    background: white !important;
}
.primary-button, .newsletter-form button {
    border: 2px solid white;
}
